<template>
<section class="page-header-section">
    <div class="circle-pattern-3">
        <img src="/assets/images/banner/circle-pattern-3.svg" width="200" alt="">
    </div>
<div class="container">
    <div class="row">
        <div class="col-md-8 col-lg-9">
            <span v-if="caption" class="header-caption">{{caption}}</span>
            <h2 class="heading">{{heading}}</h2>
            <span class="desc d-block">{{desc}}</span>
        </div>
    </div>
</div>
</section>

</template>

<script>
export default {
    props:{
        caption:"",
        heading:"",
        desc:""
    },
  data() {
    return {};
  },
};
</script>
